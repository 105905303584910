"use client";

import { useRef, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { FormContainer } from "./FormContainer/FormContainer";
import arrow from "@/shared/images/right-arr.webp";
import emailjs from "@emailjs/browser";
import { Avatars } from "@/shared/ui/Avatars/Avatars";
import "./OrderForm.css";
import { IOrderFormClient } from "../types/index";
import Link from "next/link";
import { generateEmailHtml } from "./utils/utils";
import { containsTestString } from "@/shared/lib/containsTestString";

const OrderFormClient: React.FC<IOrderFormClient> = ({
  content,
  display,
  selectedOption,
}) => {
  const form = useRef<HTMLFormElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: {},
  } = useForm();

  const [submitState, setSubmitState] = useState(false);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setSubmitState(true);

    if (containsTestString(data)) {
      console.log("Тестирующая бригада детектед");
      return null;
    }

    const userAnswers = {
      "Тип объекта": data["Тип объекта"],
      Тариф: data.option,
    };

    const contactInfo = {
      name: data.name,
      contact: data.contact,
      country: data.country,
      comment: data.comment,
    };

    const htmlContent = generateEmailHtml(userAnswers, contactInfo);

    const emailData = {
      htmlContent,
      from_name: contactInfo.name,
      reply_to: contactInfo.contact,
    };

    try {
      await emailjs.send(
        "service_j3ba6er",
        "template_we8mn3e",
        emailData,
        "oTnq4ufxCtTUQjvqL"
      );
    } catch (error) {
      console.error("Ошибка отправки:", error);
    }
  };

  const dynamicContent = submitState
    ? {
        title: content.formData.submitTitle,
        subtitle: content.formData.submitText,
        buttonText: content.formData.submitLinkText,
      }
    : {
        title: content.title,
        subtitle: content.subtitle,
        buttonText: content.formData.buttonText,
      };

  const formParametrs = {
    handleSubmit,
    content: content,
    form,
    register,
    dynamicContent,
    arrow,
  };

  return (
    <section className={"form animate"}>
      <div className={"form__wrapper"}>
        <h2 className={"form__title"}>{dynamicContent.title}</h2>
        <span className={"form__subtitle"}>{dynamicContent.subtitle}</span>
        <Avatars display={display} members={content.formAvatars} />
        {submitState ? (
          <Link
            href="/"
            className={`form__submitWrapper form__submitWrapper_goTo`}
          >
            <div className={"form__submitContainer"}>
              <span className={"form__submit"}>
                {dynamicContent.buttonText}
              </span>
              <div className={"form__submitArrow"} />
            </div>
          </Link>
        ) : (
          <FormContainer
            formParametrs={formParametrs}
            onSubmit={onSubmit} // Передаем onSubmit в дочерний компонент
            prices={!!content.cards ? content.cards : []}
            selectedOption={selectedOption}
          />
        )}
      </div>
    </section>
  );
};

export default OrderFormClient;
