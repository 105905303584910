"use client";

import { useState } from "react";
import "./PricesElement.css";
import { IPricesElement } from "./interfaces";

export const PricesElement: React.FC<IPricesElement> = ({
  prices,
  register,
  selected,
}) => {
  const [selectedOption, setSelectedOption] = useState(selected);

  const handleContainerClick = (title: string) => {
    setSelectedOption(title);
  };

  return (
    <div className="modalPrices">
      {prices.map((i) => (
        <div
          className="modalPrices__itemContainer"
          key={i.id}
          onClick={() => handleContainerClick(i.title)}
        >
          <input
            type="radio"
            id={`input_${i.id}`}
            className="modalPrices__input form__radio"
            name="modal-label"
            checked={i.title === selectedOption}
            {...register("option", { required: true })}
            value={i.title}
          />
          <label className="modalPrice__item" htmlFor={`input_${i.id}`}>
            <h6 className="modalPrices__title">{i.title}</h6>
            <span className="modalPrices__number"> {i.price}</span>
            <span className="modalPrices__symbol">{i.priceSymbols}</span>
          </label>
        </div>
      ))}
    </div>
  );
};
