export const generateEmailHtml = (
  userAnswers: { [key: string]: string },
  contactInfo: {
    name: string;
    contact: string;
    country?: string;
    budget?: string;
    comment?: string;
    type?: string;
  }
): string => {
  // Генерация списка вопросов и ответов
  const answersHtml = Object.entries(userAnswers)
    .map(
      ([key, value]) =>
        value
          ? `<li style="margin-bottom: 8px; line-height: 1.6;"><strong style="color: #bba187;">${key}:</strong> ${value}</li>`
          : ""
    )
    .join("");

  // Генерация блока контактной информации
  const contactHtml = `
    <p style="margin: 0; color: #ffffff;"><strong style="color: #bba187;">Имя:</strong> ${
      contactInfo.name || "Не указано"
    }</p>
    <p style="margin: 0; color: #ffffff;"><strong style="color: #bba187;">Контакт:</strong> ${
      contactInfo.contact || "Не указано"
    }</p>
    ${
      contactInfo.country
        ? `<p style="margin: 0; color: #ffffff;"><strong style="color: #bba187;">Страна:</strong> ${contactInfo.country}</p>`
        : ""
    }
    ${
      contactInfo.budget
        ? `<p style="margin: 0; color: #ffffff;"><strong style="color: #bba187;">Бюджет:</strong> ${contactInfo.budget}</p>`
        : ""
    }
    ${
      contactInfo.type
        ? `<p style="margin: 0; color: #ffffff;"><strong style="color: #bba187;">Тип объекта:</strong> ${contactInfo.type}</p>`
        : ""
    }
  `;

  // Генерация блока комментария, если есть
  const commentBlock = contactInfo.comment
    ? `
      <div style="margin-bottom: 20px;">
        <h3 style="font-size: 1.125rem; color: #b5b1b1;">Комментарий:</h3>
        <pre style="
          white-space: pre-wrap;
          word-wrap: break-word;
          color: #ffffff;
          background-color: #191919;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #bba187;
          margin: 0;
        ">${contactInfo.comment}</pre>
      </div>
    `
    : "";

  // Полный HTML контент
  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Form Submission</title>
    </head>
    <body style="margin: 0; padding: 0; background-color: #191919; font-family: Arial, sans-serif;">
      <!-- Основная таблица, центрируем контент -->
      <table width="100%" cellspacing="0" cellpadding="0" border="0" style="background-color: #191919; width: 100%; padding: 40px 0;">
        <tr>
          <td align="center">
            <!-- Внутренняя таблица с фиксированной шириной -->
            <table width="600" cellspacing="0" cellpadding="20" border="0" style="background-color: #323232; border-radius: 8px; color: #ffffff; text-align: left;">
              <tr>
                <td style="padding: 30px;">
                  <!-- Заголовок письма -->
                  <h2 style="margin: 0 0 20px 0; font-size: 1.75rem; font-weight: 500; color: #bba187;">
                    Form Submission
                  </h2>

                  <!-- Контактная информация -->
                  <div style="margin-bottom: 20px;">
                    <h3 style="
                      margin: 0 0 10px 0;
                      font-size: 1.125rem;
                      font-weight: 500;
                      color: #b5b1b1;
                    ">
                      Contact Information:
                    </h3>
                    ${contactHtml}
                  </div>

                  <!-- Ответы -->
                  <div style="margin-bottom: 20px;">
                    <h3 style="
                      margin: 0 0 10px 0;
                      font-size: 1.125rem;
                      font-weight: 500;
                      color: #b5b1b1;
                    ">
                      Answers:
                    </h3>
                    <ul style="
                      margin: 0;
                      padding-left: 20px;
                      color: #ffffff;
                      list-style-type: disc;
                    ">
                      ${answersHtml}
                    </ul>
                  </div>

                  <!-- Комментарий (если есть) -->
                  ${commentBlock}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>
    </html>
  `;
};
